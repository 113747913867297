import { Action } from "@liveops-portal/lib"
import { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { Modal } from "@/components/modal/modal"

interface Props<T extends FieldValues> {
  open: boolean
  loading: boolean
  action: Action
  methods: UseFormReturn<T>
  onSubmit: SubmitHandler<T>
  onClose: () => void
}

export const SeasonPublishModal = <T extends FieldValues>({
  open,
  loading,
  action,
  methods,
  onClose,
  onSubmit
}: Props<T>) => {
  const { t } = useTranslation()

  const item = "item.season"

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("action.item", {
        action: `action.${action}`,
        item
      })}
    >
      <Form
        auditable
        methods={methods}
        loading={loading}
        submitLabel={t(`action.${action}`)}
        onSubmit={onSubmit}
      >
        <FormInput
          disabled={loading}
          type="datetime-local"
          name="referenceDate"
          label={t("label.season.referenceDate")}
          rules={{
            required: true
          }}
        />
      </Form>
    </Modal>
  )
}
