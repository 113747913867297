import { Actions, UpsertBundleMutation } from "@liveops-portal/lib"
import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { BundleModal } from "@/components/bundle-modal/bundle-modal"
import { useAppSelector } from "@/hooks/store"
import { useUpsertBundleMutation } from "@/store/api/bundle"
import { selectGame } from "@/store/slices/context"

interface Props extends UpsertBundleMutation {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

interface FormData extends Props {
  bundleName: string
  seasonIds: string[]
}

export const BundleUpdate: React.FC<Props> = ({
  open,
  setOpen,
  bundleId,
  bundleName,
  seasonIds
}: Props) => {
  const gameId = useAppSelector(selectGame)
  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      bundleName,
      seasonIds
    }
  })
  const { reset: resetForm } = methods

  const [upsertBundle, mutation] = useUpsertBundleMutation()
  const { isSuccess, isLoading } = mutation

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm, setOpen])

  const onSubmitHandler = (data: FormData) =>
    upsertBundle({ ...data, gameId, bundleId })

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [isSuccess, onCloseHandler])

  return (
    <BundleModal
      open={open}
      loading={isLoading}
      action={Actions.update}
      methods={methods}
      onSubmit={onSubmitHandler}
      onClose={onCloseHandler}
    />
  )
}
