import {
  ByEnvQuery,
  ByGameIdQuery,
  BySpaceIdQuery,
  DataState,
  Environment,
  Game,
  OperationReason
} from "."

export enum GameServerBuildDeploymentType {
  DOCKER = "Docker",
  ZIP = "ZIP"
}

export enum GameServerBuildType {
  debug = "buildType.debug",
  profile = "buildType.profile",
  release = "buildType.release",
  retail = "buildType.retail",
  unknown = "buildType.unknown"
}

export interface GameServerBuild {
  gameId: Game["gameId"]
  buildId: string
  buildType: keyof typeof GameServerBuildType
  codeChangeList: string | null
  dataChangeList: string | null
  deploymentType: keyof typeof GameServerBuildDeploymentType
  state: keyof typeof DataState
  lastUpdate: string
  buildName: string
  uploadPath: string
  preserved: boolean
  deployments?: GameServerBuildDeployment[]
}

export enum GameServerDeploymentState {
  InProgress = "state.inProgress",
  Deployed = "state.deployed"
}

export enum GameServerDeployedState {
  Unknown = "state.unknown",
  NotUploaded = "state.notUploaded",
  Shutdown = "state.shutdown",
  Deploying = "state.deploying",
  Ready = "state.ready"
}

export enum GameServerRegions {
  Virginia = "region.virginia",
  Oregon = "region.oregon",
  Frankfurt = "region.frankfurt",
  Seoul = "region.seoul"
}

export interface GameServerRegion {
  regionName: keyof typeof GameServerRegions | null
  minIdleCount: number | null
  maxIdleCount: number | null
}

export interface GameServerBuildDeployment {
  spaceId: string
  buildId: string
  numberOfCores: number
  cpuType: string
  state: keyof typeof GameServerDeploymentState
}

export interface GameServerBuildStatus {
  buildId: string
  status: keyof typeof GameServerDeployedState
}

export type AllGameServerBuildsRequest = ByGameIdQuery & ByEnvQuery

export type GameServerBuildStatusRequest = ByGameIdQuery &
  BySpaceIdQuery &
  ByEnvQuery

export type GameServerBuildWarmUpRequest = ByGameIdQuery &
  BySpaceIdQuery & {
    envName: Environment
    gameServerPerMachine: number
    regions: GameServerRegion[]
    hoursWarmup: number
  } & OperationReason

export type GameServerBuildStopRequest = ByGameIdQuery &
  BySpaceIdQuery &
  ByEnvQuery &
  OperationReason
