import { Stack, Typography } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"
import { BundleCard } from "@/components/bundle-card/bundle-card"
import { BundleCreate } from "@/components/bundle-create/bundle-create"
import { SeasonCard } from "@/components/season-card/season-card"
import { SeasonCreate } from "@/components/season-create/season-create"
import { Spinner } from "@/components/spinner/spinner"
import { useBundles } from "@/hooks/useBundles"
import { usePageActions } from "@/hooks/usePageActions"

/**
 * Component representing the Season Designer Overview page.
 */
export const SeasonDesignerOverviewPage: React.FC = () => {
  const { t } = useTranslation()

  const { bundles, standaloneSeasons, isFetching } = useBundles()

  usePageActions([
    <SeasonCreate key="season-create" />,
    <BundleCreate key="bundle-create" />
  ])

  return (
    <Stack sx={{ gap: 1 }}>
      <Spinner loading={isFetching} />

      <Typography level="h3">{t("item.bundles")}</Typography>
      {bundles?.length ? (
        <Stack sx={{ gap: 1 }}>
          {bundles.map(({ bundleId }) => (
            <BundleCard key={bundleId} bundleId={bundleId} />
          ))}
        </Stack>
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.bundles",
            parent: "item.game"
          })}
        </Typography>
      )}

      <Typography level="h3">{t("label.season.standaloneSeasons")}</Typography>
      {standaloneSeasons?.length ? (
        <Stack sx={{ gap: 1 }}>
          {standaloneSeasons.map(({ seasonId }) => (
            <SeasonCard key={seasonId} seasonId={seasonId} />
          ))}
        </Stack>
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.seasons",
            parent: "item.game"
          })}
        </Typography>
      )}
    </Stack>
  )
}
