import {
  AllGameServerBuildsRequest,
  ApiError,
  GameServerBuild,
  GameServerBuildStatus,
  GameServerBuildStatusRequest,
  GameServerBuildStopRequest,
  GameServerBuildWarmUpRequest,
  sortByKey
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const gameServerBuildApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "GameServerBuild",
      "GameServerBuildDeploymentStatus",
      "GameServerBuildDeploymentError"
    ]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      findAllGameServerBuilds: builder.query<
        GameServerBuild[],
        AllGameServerBuildsRequest
      >({
        query: ({ gameId }) => ({
          url: `/game/${gameId}/game-server-builds`
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ buildId }) => ({
                  type: "GameServerBuild" as const,
                  id: buildId
                })),

                { type: "GameServerBuild", id: "ALL" }
              ]
            : [{ type: "GameServerBuild", id: "ALL" }],
        onQueryStarted({ gameId }, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.findAllGameServerBuilds.error", {
                gameId
              }),
              error: error as ApiError
            })
          })
        },
        transformResponse: (builds: GameServerBuild[]) =>
          sortByKey(builds, "lastUpdate", "desc")
      }),
      findGameServerBuildStatus: builder.query<
        GameServerBuildStatus,
        GameServerBuildStatusRequest
      >({
        query: ({ env, gameId, spaceId }) => ({
          url: `/game/${gameId}/space/${spaceId}/game-server-build`,
          params: { envName: env }
        }),
        providesTags: (result, _, { spaceId }) => {
          return result
            ? [{ type: "GameServerBuildDeploymentStatus", id: spaceId! }]
            : ["GameServerBuildDeploymentError"]
        },
        onQueryStarted({ spaceId }, { queryFulfilled }) {
          queryFulfilled.catch(({ error: e }) => {
            const error = e as ApiError

            if (!error.errCode) {
              notify.error({
                message: i18next.t("api.findGameServerBuildStatus.error", {
                  spaceId
                }),
                error
              })
            }
          })
        }
      }),
      warmUpGameServer: builder.mutation<void, GameServerBuildWarmUpRequest>({
        query: ({
          gameId,
          spaceId,
          envName,
          gameServerPerMachine,
          regions,
          hoursWarmup,
          qdOperationReason
        }) => ({
          url: `/game/${gameId}/space/${spaceId}/game-server-build/warmup`,
          body: { envName, gameServerPerMachine, regions, hoursWarmup },
          method: "POST",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { spaceId }) =>
          !error
            ? [{ type: "GameServerBuildDeploymentStatus", id: spaceId! }]
            : ["GameServerBuildDeploymentError"],
        onQueryStarted({ spaceId }, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.warmUpGameServer.error", {
                spaceId
              }),
              error: error as ApiError
            })
          })
        }
      }),
      stopGameServer: builder.mutation<void, GameServerBuildStopRequest>({
        query: ({ gameId, spaceId, qdOperationReason }) => ({
          url: `/game/${gameId}/space/${spaceId}/game-server-build/stop`,
          method: "POST",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { spaceId }) => [
          { type: "GameServerBuildDeploymentStatus", id: spaceId! }
        ],
        onQueryStarted({ spaceId }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.stopGameServer.success", { spaceId })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.stopGameServer.error", {
                  spaceId
                }),
                error: error as ApiError
              })
            })
        }
      })
    }),
    overrideExisting: false
  })

export const {
  useFindAllGameServerBuildsQuery,
  useFindGameServerBuildStatusQuery,
  useWarmUpGameServerMutation,
  useStopGameServerMutation
} = gameServerBuildApi
