export const enUS = {
  translation: {
    api: {
      createActiveDeployment: {
        error: "Could not set active deployment.",
        success: "Bundle successfully deployed to space."
      },
      createBundle: {
        error: "An error occurred while creating the bundle.",
        success: "Bundle created."
      },
      editBundle: {
        error: "An error occurred while editing the bundle.",
        success: "Bundle {{bundleName}} edited."
      },
      createHealthcheck: {
        error: "An error occurred while registering the healthcheck.",
        success: "{{serviceName}} healthcheck registered."
      },
      createSeason: {
        error: "An error occurred while creating the season.",
        success: "Season created."
      },
      createSeasonComponent: {
        success: "$({{componentType}}) {{displayName}} created."
      },
      createSpace: {
        error: "An error occurred while creating the space.",
        success: "Space created."
      },
      deleteBundle: {
        error: "An error occurred while deleting the bundle.",
        success: "Bundle deleted."
      },
      deleteConfigCategory: {
        error:
          "An error occurred while deleting the configuration category {{configName}}.",
        success: "Configuration category {{configName}} deleted."
      },
      deleteHealthcheck: {
        error: "An error occurred while removing the healthcheck.",
        success: "{{serviceName}} healthcheck removed."
      },
      deleteOverrideValue: {
        error: "Could not reset value for feature switch {{switchName}}.",
        success: "{{switchName}} feature switch reset to default."
      },
      deleteSeason: {
        error: "An error occurred while deleting the season.",
        success: "Season deleted."
      },
      deleteSeasonComponent: {
        success: "$({{componentType}}) deleted."
      },
      findAllConfigs: {
        error: "Could not retrieve configuration for space {{spaceId}}."
      },
      findAllGameDataTypes: {
        error:
          "Could not retrieve data types for game {{gameId}}, version {{dataVersion}}."
      },
      findAllGameData: {
        error:
          "Could not retrieve data for game {{gameId}}, version {{dataVersion}}, dataType {{dataType}}."
      },
      findAllGameDataVersions: {
        error: "Could not retrieve game data versions for game {{gameId}}."
      },
      findPlayerNamecardProfilesBySdkuid: {
        error: "Could not get player profile for SDKUID {{sdkuid}}."
      },
      getActiveDeployment: {
        error: "Could not retrieve active deployment."
      },
      getAllApplicationsByGameId: {
        error: "Could not retrieve applications for game {{gameId}}."
      },
      getAllBuildsByGameId: {
        error: "Could not retrieve builds for game {{gameId}}."
      },
      findAllFeatureSwitches: {
        error: "Could not retrieve feature switches for space {{spaceId}}."
      },
      getAllGames: {
        error: "Could not retrieve games."
      },
      findAllSpaces: {
        error: "Could not retrieve spaces."
      },
      getAudits: { error: "Could not retrieve audit logs." },
      getBundles: {
        error: "Could not retrieve bundles."
      },
      getEnvironments: {
        error: "Could not retrieve environments."
      },
      getGameDataBySeasonId: {
        error: "Could not retrieve game data for season {{seasonId}}."
      },
      findGameServerBuildStatus: {
        error:
          "Could not retrieve game server build status for space {{spaceId}}."
      },
      findAllGameServerBuilds: {
        error: "Could not retrieve game server builds for game {{gameId}}."
      },
      getHealthchecks: {
        error: "Could not retrieve healthchecks."
      },
      getSeasonComponentItems: {
        error: "Could not retrieve {{componentType}} for season {{seasonId}}."
      },
      getSeasons: {
        error: "Could not retrieve seasons for game {{gameId}}."
      },
      getSeasonDraft: {
        error: "Could not retrieve draft for season {{seasonId}}."
      },
      publishSeasonRevision: {
        error: "An error occurred while publishing the revision.",
        success: "Season revision {{revision}} published."
      },
      resetThreadOfFateProgression: {
        error: "An error occurred while resetting Thread of Fate progression.",
        success: "Thread of Fate progression reset."
      },
      sendRequest: {
        error: "An error occurred while sending the request."
      },
      setOverrideValue: {
        error: "Could not override value for feature switch {{switchName}}.",
        success: "{{switchName}} feature switch updated."
      },
      stopGameServer: {
        error: "Could not shutdown game server on space {{spaceId}}.",
        success: "Game server on space {{spaceId}} shutdown successfully."
      },
      toggleHealthcheck: {
        error: "An error occurred while toggling the healthcheck.",
        success: {
          disabled: "{{serviceName}} healthcheck disabled.",
          enabled: "{{serviceName}} healthcheck enabled."
        }
      },
      triggerHealthchecks: {
        error: "An error occurred while checking the statuses.",
        success: "Statuses have been updated."
      },
      updateConfigCategory: {
        error: "Could not update configuration category {{configName}}.",
        success: "Configuration category {{configName}} updated."
      },
      updateSeason: {
        error: "An error occurred while updating the season.",
        success: "Season updated."
      },
      updateSeasonComponent: {
        success: "$({{componentType}}) {{displayName}} updated."
      },
      warmUpGameServer: {
        error: "Could not warm up game server on space {{spaceId}}.",
        success: "Game server on space {{spaceId}} is ready."
      }
    },
    app: {
      activeDeployment: "Active Deployment",
      audit: "Audit",
      dataViewer: "Data Viewer",
      general: "General",
      health: "Health",
      liveBalancing: "Live Balancing",
      liveops: "LiveOps",
      massGrant: "Mass Grant",
      name: "LiveOps Portal",
      news: "News",
      notFound: "Not Found",
      overview: "Overview",
      playerFinder: "Player Finder",
      playerReports: "Player Reports",
      seasonDesigner: "Season Designer",
      settings: "Settings",
      shop: "Shop",
      support: "Support",
      telemetry: "Telemetry",
      timeline: "Timeline",
      toolbox: "Toolbox"
    },
    action: {
      add: "Add",
      cancel: "Cancel",
      clear: "Clear",
      clearAll: "Clear all",
      close: "Close",
      collapse: "Collapse",
      create: "Create",
      dashboard: "Go to dashboard",
      delete: "Delete",
      expand: "Expand",
      home: "Back to home",
      item: "$t({{action}}) $t({{item}}, lower)",
      language: "Switch to Chinese (Simplified)",
      microsoft: "Continue with Microsoft",
      override: "Override",
      publish: "Publish",
      refresh: "Refresh",
      register: "Register",
      remove: "Remove",
      resetToDefault: "Reset to default",
      search: "Search",
      select: "Select",
      selectAll: "Select all",
      selectNone: "Select none",
      send: "Send",
      set: "Set",
      signin: "Sign in",
      stop: "Stop",
      trigger: "Trigger",
      unselect: "Unselect",
      unselectAll: "Unselect all",
      update: "Update",
      view: "View",
      warm: "Warm"
    },
    buildType: {
      debug: "Debug",
      profile: "Profile",
      release: "Release",
      retail: "Retail",
      unknown: "Unknown"
    },
    common: {
      all: "All",
      free: "Free",
      languageCode: "CN",
      no: "No",
      none: "None",
      off: "Off",
      on: "On",
      this: "This",
      username: "Username",
      yes: "Yes",
      notApplicable: "N/A"
    },
    description: {
      featureSwitch: {
        ActiveCharacterIds:
          "Specifies the list of in-game active characters. Default setting: <0 />.",
        DisabledSpells:
          "Specifies the list of in-game disabled spells. Default setting: <0 />.",
        FileUpload_Replay:
          "Conditions the upload of events replay log. <0>$t(state.{{state}})</0> by default.",
        FileUpload:
          "Global flag to condition the upload of files from the game. <0>$t(state.{{state}})</0> by default.",
        JoinInProgress:
          "Activate/Deactivate player join in progress. <0>$t(state.{{state}})</0> by default.",
        PreLobby:
          "Conditions availability of the in-game prelobby. <0>$t(state.{{state}})</0> by default.",
        PrimaryShop:
          "Conditions the availability of the primary shop. <0>$t(state.{{state}})</0> by default.",
        SecondaryShop:
          "Conditions the availability of the secondary shop. <0>$t(state.{{state}})</0> by default.",
        Telemetry:
          "Activate/Deactivate Telemetry. <0>$t(state.{{state}})</0> by default.",
        VoiceChat:
          "Conditions availability of the in-game voicechat. <0>$t(state.{{state}})</0> by default."
      }
    },
    error: {
      bundle: {
        emptyBundles: "No bundles available, please create at least one."
      },
      decode: "An error occurred while decoding the response.",
      deployment: {
        emptyDeployment: "No deployment available."
      },
      gameServerBuildStatus: {
        "11": "Build status cannot be retrieved in Playfab spaces.",
        "12": "No deployment available for this space.",
        generic: "An error occurred while fetching the build status.",
        noSpace: "Please select a space to view game server build information.",
        noRegion: "Please select a region."
      },
      generic: "An error occurred.",
      healthcheck: {
        serviceNames: "Please select at least one service."
      },
      invalidJson: "JSON is not valid",
      max: "$t({{label}}) exceeds limit",
      maxLength: "$t({{label}}) exceeds character limit",
      min: "$t({{label}}) is not a valid number",
      minLength: "$t({{label}}) is too short",
      required: "$t({{label}}) is required",
      season: {
        maxBattlepassDelay: "Delay cannot be longer than season duration",
        maxBattlepassLength: "Length cannot be longer than season duration",
        bundleSelectionLength: "Please select at least one season",
        emptySeasons: "No seasons available, please create at least one."
      },
      space: {
        spaceName: "A space name cannot start or end with an hypen",
        spacePrefix: "The space prefix must end with an underscore"
      },
      url: "$t({{label}}) must be a valid URL"
    },
    hint: {
      closeNotificationCenter: "Close notification center",
      copy: "Copy to clipboard",
      maxBattlepassDelay: "From season start",
      openNotificationCenter: "Open notification center",
      seasonDuration: "Season duration: {{seasonLength}} days",
      switchToDark: "Switch to dark mode",
      switchToLight: "Switch to light mode",
      spaceName: "No spaces, no leading/trailing hyphens",
      spacePrefix: "No spaces, ends with underscore"
    },
    id: {
      app: "appId",
      build: "buildId",
      game: "gameId",
      item: "itemId",
      season: "seasonId",
      space: "spaceId",
      uuid: "UUID"
    },
    item: {
      activeDeployment: "Active Deployment",
      application: "Application",
      applications: "Applications",
      battlepass: "Battlepass",
      battlepasses: "Battlepasses",
      build: "Build",
      builds: "Builds",
      bundle: "Bundle",
      bundles: "Bundles",
      categories: "Categories",
      category: "Category",
      challenge: "Challenge",
      challenges: "Challenges",
      communityVotes: "Community Votes",
      configuration: "Configuration",
      configurations: "Configurations",
      data: "Data",
      dataVersion: "Data version",
      dataVersions: "Data versions",
      dataType: "Data type",
      dataTypes: "Data types",
      env: "Env",
      environment: "Environment",
      environments: "Environments",
      envs: "Envs",
      featureSwitch: "Feature Switch",
      featureSwitches: "Feature Switches",
      game: "Game",
      game_initial: "G",
      games: "Games",
      gameServerBuild: "Game Server Build",
      gameServerBuilds: "Game Server Builds",
      healthcheck: "Healthcheck",
      healthchecks: "Healthchecks",
      level: "Level",
      levelReward: "Level reward",
      levelRewards: "Level rewards",
      levels: "Levels",
      log: "Log",
      logs: "Logs",
      news: "News",
      notification: "Notification",
      notifications: "Notifications",
      player: "Player",
      players: "Players",
      playlist: "Playlist",
      playlists: "Playlists",
      remoteLogs: "Remote Logs",
      region: "Region",
      regions: "Regions",
      reward: "Reward",
      rewards: "Rewards",
      season: "Season",
      seasons: "Seasons",
      service: "Service",
      services: "Services",
      sidebar: "Sidebar",
      space: "Space",
      space_initial: "S",
      spaces: "Spaces",
      track: "Track",
      tracks: "Tracks",
      value: "Value",
      values: "Values"
    },
    label: {
      actions: "Actions",
      audit: {
        UserName: "User",
        OperationReason: "Reason",
        HttpRequestUrl: "URL",
        HttpRequestMethod: "Method",
        HttpRequestHost: "Host",
        HttpRequestPath: "Endpoint",
        HttpRequestUrlParams: "URL Params",
        HttpRequestBody: "Body",
        HttpResponseStatusCode: "Status",
        ProjectName: "Service",
        Environment: "$t(item.env)",
        TimeGenerated: "Date",
        range: "Range ($t({{unit}}))"
      },
      body: "Body",
      boolean: "Boolean",
      bundle: {
        bundleId: "Bundle ID",
        creationDate: "Creation date",
        seasonsInBundle: "Seasons in bundle",
        bundleVersion: "Bundle version"
      },
      configuration: {
        configName: "Configuration category",
        privateConfig: "Private configuration",
        publicConfig: "Public configuration"
      },
      defaultSetting: "Default setting",
      deployment: {
        deploymentId: "Deployment ID",
        deploymentDate: "Deployment date",
        deployedBundleInformation: "Deployed bundle information"
      },
      endpoint: "Endpoint",
      envSelector: "Env selector",
      filter: "Filter",
      gameSelector: "Game selector",
      gameServerBuild: {
        buildType: "Build type",
        codeChangelist: "Code Changelist",
        dataChangelist: "Data Changelist",
        deploymentType: "Deployment type",
        hoursWarmup: "Warm up duration",
        maxIdleCount: "Maximum count",
        minIdleCount: "Target idle",
        preserved: "Preserved",
        state: "Build state",
        warmUpGameServer: "Warm up game server"
      },
      healthcheck: {
        deepCheck: "Deep check",
        msTeamsNotifierWebhookId: "Teams Webhook ID",
        serviceUrl: "Service URL",
        subscriptionKey: "Subscription Key"
      },
      history: "History",
      list: "List",
      method: "Method",
      nextPage: "Go to next page",
      options: "Options",
      originalString: "Original string",
      pagination: "{{from}}-{{to}} of {{count}}",
      playerId: "Player ID",
      previousPage: "Go to previous page",
      queryParams: "Query Params",
      reason: "Reason",
      resetToFProgression: "Reset Thread of Fate progression",
      response: "Response",
      resetFilters: "Reset filters",
      rowsPerPage: "Rows per page",
      season: {
        referenceDate: "Reference date",
        durationTypes: {
          fullSeason: "Full Season",
          specificLength: "Specific length"
        },
        levelRewards: "Track {{trackIdx}}, level {{levelIdx}} rewards",
        progressInMenu: "Progress in menu",
        progressType: "Progress type",
        progressTypes: {
          accumulation: "Accumulative",
          highest: "Highest"
        },
        latestPublishedRevision: "Latest published revision",
        scope: "Scope",
        standaloneSeasons: "Standalone Seasons",
        threshold: "Threshold",
        viewData: "View Data",
        xpPerLevel: "XP per level",
        xpRequired: "XP required",
        xpRequiredTypes: {
          custom: "Custom per level",
          linear: "Linear"
        }
      },
      seasonSelector: "Season selector",
      spaceSelector: "Space selector",
      time: {
        delay: "Delay",
        duration: "Duration",
        lastModified: "Last modified",
        length: "Length",
        older: "Older",
        today: "Today"
      }
    },
    message: {
      appStage:
        "You're currently using the portal stage intended for developers. Please use the <0>live portal</0> instead.",
      configuration: {
        EmptySpaceConfiguration: "Space configuration is empty.",
        fixNeeded:
          "To use this feature, please fix this space's configuration:",
        generalReadOnly: "The general configuration of a space is read-only.",
        MissingConfigurationCategory:
          'Category "{{category}}" is missing from the space configuration.',
        MissingConfigurationKeys:
          'Private keys missing for category "{{category}}": {{missingKeys}}.'
      },
      dataViewer: {
        associatedVersion:
          "Version {{dataVersion}} is associated with season {{seasonName}}."
      },
      noItems: {
        namedParent:
          "No $t({{item}}, lower) found for $t({{parent}}, lower) {{parentName}}.",
        parent:
          "No $t({{item}}, lower) found for the selected $t({{parent}}, lower).",
        root: "No $t({{item}}, lower) found.",
        selectedCriteria:
          "No $t({{item}}, lower) found for the selected criteria."
      },
      notFound: {
        explanation:
          "You may have misstyped the address or the page may have moved.",
        title: "Sorry! The page you're looking for doesn't exist."
      },
      notifications: {
        none: "No notifications yet.",
        noneOlder: "No older notifications.",
        noneToday: "No notifications today."
      },
      notified: "The Portal team has been notified.",
      sorry: "Sorry for the inconvenience.",
      uninitialized: {
        parent:
          "Please select a $t({{parent}}, lower) to view the associated $t({{item}}, lower).",
        root: "Please select a $t({{parent}}, lower) to use this feature."
      },
      noRequest: "No request yet."
    },
    modifier: {
      current: "Current $t({{item}}, lower)",
      default: "Default $t({{item}}, lower)",
      name: "$t({{item}}) name",
      new: "New $t({{item}}, lower)",
      override: "$t({{item}}) override",
      prefix: "$t({{item}}) prefix"
    },
    placeholder: {
      reason: "Please provide a short reason for this operation",
      season: "Select a season",
      option: "Select an option",
      space: "Select a space"
    },
    region: {
      frankfurt: "EU West (Frankfurt, Germany)",
      oregon: "US West (The Dalles, Oregon)",
      virginia: "US East (Ashburn, Virginia)",
      seoul: "Asia North East (Seoul, South Korea)"
    },
    service: {
      playfab: "Playfab",
      udata: "Udata",
      unimatrix: "Unimatrix",
      unipublish: "Unipublish"
    },
    severity: {
      error: "Error",
      info: "Info",
      success: "Success",
      warning: "Warning"
    },
    state: {
      configured: "Configured",
      copied: "Copied to clipboard",
      deployed: "Deployed",
      deploying: "Deploying",
      disabled: "Disabled",
      disabledDefault: "<0>Disabled</0> by default",
      draft: "Draft",
      enabled: "Enabled",
      enabledDefault: "<0>Enabled</0> by default",
      failing: "Failing",
      inProgress: "In Progress",
      inUse: "In use",
      loading: "Loading",
      notConfigured: "Not configured",
      notUploaded: "Not uploaded",
      operational: "Operational",
      overridden: "Overridden",
      preserved: "Preserved",
      published: "Published",
      readOnly: "Read Only",
      ready: "Ready",
      readyForUse: "Ready for use",
      selected: "Selected",
      shutdown: "Shutdown",
      stopping: "Stopping",
      unknown: "Unknown",
      waitingForUpload: "Waiting for upload"
    },
    time: {
      duration: {
        day: "{{count}} $t(time.unit.day, {'count': {{count}} })",
        hour: "{{count}} $t(time.unit.hour, {'count': {{count}} })",
        minute: "{{count}} $t(time.unit.minute, {'count': {{count}} })",
        month: "{{count}} $t(time.unit.month, {'count': {{count}} })",
        second: "{{count}} $t(time.unit.second, {'count': {{count}} })",
        week: "{{count}} $t(time.unit.week, {'count': {{count}} })",
        year: "{{count}} $t(time.unit.year, {'count': {{count}} })"
      },
      unit: {
        day: "day",
        day_other: "days",
        day_short: "d",
        hour: "hour",
        hour_other: "hours",
        hour_short: "h",
        minute: "minute",
        minute_other: "minutes",
        minute_short: "min",
        month: "month",
        month_other: "months",
        month_short: "m",
        second: "second",
        second_other: "seconds",
        second_short: "s",
        week: "week",
        week_other: "weeks",
        week_short: "w",
        year: "year",
        year_other: "years",
        year_short: "y"
      }
    }
  }
}
