import { Actions, UpsertBundleMutation } from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import { Plus } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { BundleModal } from "@/components/bundle-modal/bundle-modal"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useUpsertBundleMutation } from "@/store/api/bundle"
import { selectGame } from "@/store/slices/context"

interface FormData extends UpsertBundleMutation {}

export const BundleCreate: React.FC = () => {
  const dispatch = useAppDispatch()
  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      qdOperationReason: "Creating bundle"
    }
  })
  const { reset: resetForm } = methods
  const gameId = useAppSelector(selectGame)
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const [upsertBundle, mutation] = useUpsertBundleMutation()
  const { isSuccess, isLoading: isLoadingCreate } = mutation

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm])

  const onSubmitHandler = (data: FormData) => upsertBundle({ ...data, gameId })

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [dispatch, isSuccess, onCloseHandler])

  return (
    <>
      <Button
        variant="outlined"
        color="success"
        onClick={() => setOpen(true)}
        startDecorator={<Plus />}
      >
        {t("action.item", {
          action: "action.create",
          item: "item.bundle"
        })}
      </Button>

      <BundleModal
        open={open}
        loading={isLoadingCreate}
        action={Actions.create}
        methods={methods}
        onSubmit={onSubmitHandler}
        onClose={onCloseHandler}
      />
    </>
  )
}
