import { Action, Actions } from "@liveops-portal/lib"
import { Typography } from "@mui/joy"
import { useEffect } from "react"
import {
  FieldValues,
  Path,
  PathValue,
  SubmitHandler,
  UseFormReturn
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "@/components/form/form"
import { FormSelect } from "@/components/form-select/form-select"
import { Modal } from "@/components/modal/modal"
import { useBundles } from "@/hooks/useBundles"

interface Props<T extends FieldValues> {
  open: boolean
  loading: boolean
  action: Action
  methods: UseFormReturn<T>
  onSubmit: SubmitHandler<T>
  onClose: () => void
}

export const ActiveDeploymentModal = <T extends FieldValues>({
  open,
  loading,
  action,
  methods,
  onClose,
  onSubmit
}: Props<T>) => {
  const { t } = useTranslation()

  const { bundles } = useBundles()

  const item = "item.activeDeployment"

  const bundleIdValue: string = methods.watch("bundleId" as Path<T>)

  const { setValue } = methods

  useEffect(() => {
    const matchedBundle = bundles?.find(
      ({ bundleId }) => bundleId === bundleIdValue
    )

    setValue(
      "bundleVersion" as Path<T>,
      matchedBundle?.bundleVersion as PathValue<T, Path<T>>
    )
  }, [bundleIdValue, bundles, setValue])

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("action.item", {
        action: `action.${action}`,
        item
      })}
    >
      {bundles ? (
        <Form
          auditable={action === Actions.set}
          methods={methods}
          loading={loading}
          submitLabel={t("action.item", {
            action: `action.${action}`,
            item
          })}
          onSubmit={onSubmit}
          sx={{ gap: 2, flexDirection: "column" }}
        >
          <FormSelect
            name="bundleId"
            label={t("item.bundle")}
            options={bundles.map(({ bundleName, bundleId }) => ({
              value: bundleId,
              label: bundleName
            }))}
          />
        </Form>
      ) : (
        <Typography color="warning">
          {t("error.bundle.emptyBundles")}
        </Typography>
      )}
    </Modal>
  )
}
