import { InteractionRequiredAuthError } from "@azure/msal-browser"
import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError
} from "@reduxjs/toolkit/query/react"
import { captureException } from "@sentry/react"
import { MSAL_INSTANCE, MSAL_LOGIN_REQUEST } from "@/constants/auth"
import { resetOperation, selectOperationReason } from "@/store/slices/audit"
import { selectEnv } from "@/store/slices/context"

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.__CLIENT_URL}/api/`,
  prepareHeaders: async (headers, { getState }) => {
    /* c8 ignore start */
    // Auth
    const activeAccount = MSAL_INSTANCE.getActiveAccount()
    const accounts = MSAL_INSTANCE.getAllAccounts()

    if (activeAccount || accounts.length > 0) {
      const account = accounts[0]

      if (account) {
        const qdOperationReason = selectOperationReason(getState())
        const currentLocale = localStorage.getItem("localization")

        try {
          const auth =
            await MSAL_INSTANCE.acquireTokenSilent(MSAL_LOGIN_REQUEST)

          if (!headers.get("env")) headers.set("env", selectEnv(getState()))
          qdOperationReason &&
            headers.set("QD-Operation-Reason", qdOperationReason)
          currentLocale && headers.set("Content-Language", currentLocale)
          auth.accessToken &&
            headers.set("Authorization", `Bearer ${auth.accessToken}`)
          account.username && headers.set("QD-Username", account.username)
        } catch (e) {
          if (e instanceof InteractionRequiredAuthError) {
            await MSAL_INSTANCE.acquireTokenRedirect({
              ...MSAL_LOGIN_REQUEST,
              account
            })
          }
        }
      }
    }

    return headers
    /* c8 ignore end */
  }
})

/* Initial configuration of the RTK Query. Endpoints should be configured in respective features */
export const api = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions)

      if (result.error) {
        return {
          error: result.error.data
        }
      }

      // Reset operationReason on successful call
      if (result.meta?.request.headers.has("Qd-Operation-Reason")) {
        const { dispatch } = api
        dispatch(resetOperation())
      }

      return { data: result.data }
    } catch (error) {
      const { status, data } = error as FetchBaseQueryError
      const { message } = error as Error
      if (
        (typeof status === "number" && status >= 499) ||
        typeof status === "string"
      ) {
        captureException(error)
      }

      return {
        error: {
          data: data || message,
          status: status || "ERROR"
        }
      }
    }
  },
  endpoints: () => ({})
})
