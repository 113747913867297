import { ApiError, GameServerDeployedState } from "@liveops-portal/lib"
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardOverflow,
  Chip,
  ColorPaletteProp,
  Stack,
  Link,
  Typography,
  CircularProgress
} from "@mui/joy"
import { Fade } from "@mui/material"
import { EditPencil, PauseSolid, PlaySolid } from "iconoir-react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { GameServerBuildWarm } from "@/components/game-server-build-warm/game-server-build-warm"
import notify from "@/helpers/notify"
import { useAppSelector } from "@/hooks/store"
import {
  useFindAllGameServerBuildsQuery,
  useFindGameServerBuildStatusQuery,
  useStopGameServerMutation
} from "@/store/api/game-server-build"
import {
  selectEnv,
  selectGame,
  selectSpace,
  selectStage
} from "@/store/slices/context"

const getBuildStatusColor = (
  status: keyof typeof GameServerDeployedState
): ColorPaletteProp => {
  switch (GameServerDeployedState[status]) {
    case GameServerDeployedState.Unknown:
      return "neutral"
    case GameServerDeployedState.NotUploaded:
      return "warning"
    case GameServerDeployedState.Shutdown:
      return "danger"
    case GameServerDeployedState.Deploying:
      return "warning"
    case GameServerDeployedState.Ready:
      return "success"
  }
}

const getGameServerActionIcon = (
  status: keyof typeof GameServerDeployedState | undefined
) => {
  if (!status) return <PlaySolid />

  switch (GameServerDeployedState[status]) {
    case GameServerDeployedState.Unknown:
    case GameServerDeployedState.NotUploaded:
    case GameServerDeployedState.Shutdown:
      return <PlaySolid />
    case GameServerDeployedState.Deploying:
      return null
    case GameServerDeployedState.Ready:
      return <PauseSolid />
  }
}

export const OverviewGameServerBuild = () => {
  const { t, i18n } = useTranslation()
  const stage = useAppSelector(selectStage)
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const {
    currentData: gameServerBuildStatus,
    error: e = {},
    isError,
    isFetching: isFetchingStatus,
    refetch
  } = useFindGameServerBuildStatusQuery(
    { env, gameId, spaceId },
    {
      skip: !stage || !env || !gameId || !spaceId
    }
  )
  const prevGameServerBuildStatus = useRef<GameServerDeployedState | null>(null)
  const { data: gameServerBuild, isFetching: isFetchingBuild } =
    useFindAllGameServerBuildsQuery(
      { env, gameId },
      {
        skip: !env || !gameId,
        selectFromResult: (result) => ({
          ...result,
          data: result.data?.find(
            (item) => item.buildId === gameServerBuildStatus?.buildId
          )
        })
      }
    )
  const [stopGameServer, { isLoading: isStopping, isSuccess: isStopped }] =
    useStopGameServerMutation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const isFetching = isFetchingStatus || isFetchingBuild
  const isDeploying = useMemo(
    () =>
      gameServerBuildStatus &&
      GameServerDeployedState[gameServerBuildStatus.status] ===
        GameServerDeployedState.Deploying,
    [gameServerBuildStatus]
  )
  const isReady = useMemo(
    () =>
      !isFetchingStatus &&
      gameServerBuildStatus &&
      GameServerDeployedState[gameServerBuildStatus.status] ===
        GameServerDeployedState.Ready,
    [isFetchingStatus, gameServerBuildStatus]
  )
  const isNotUploaded = useMemo(
    () =>
      !isFetchingStatus &&
      gameServerBuildStatus &&
      GameServerDeployedState[gameServerBuildStatus.status] ===
        GameServerDeployedState.NotUploaded,
    [isFetchingStatus, gameServerBuildStatus]
  )

  const { errCode, message } = e as ApiError

  useEffect(() => {
    if (isDeploying) {
      prevGameServerBuildStatus.current = GameServerDeployedState.Deploying

      const interval = setInterval(() => {
        refetch()
      }, 5000)

      return () => clearInterval(interval)
    }
  }, [isDeploying, refetch])

  useEffect(() => {
    if (
      prevGameServerBuildStatus.current === GameServerDeployedState.Deploying &&
      isReady
    ) {
      prevGameServerBuildStatus.current = null
      notify.success(t("api.warmUpGameServer.success", { spaceId }))
    }
  }, [isReady, spaceId, t])

  const onActionButtonClick = useCallback(() => {
    if (isReady) {
      stopGameServer({
        env,
        gameId,
        spaceId,
        qdOperationReason: "Game server shutdown from LiveOps Portal"
      })
    } else {
      setOpenModal(true)
    }
  }, [env, gameId, spaceId, isReady, stopGameServer])

  return (
    <>
      <GameServerBuildWarm open={openModal} setOpen={setOpenModal} />

      <Stack sx={{ gap: 2 }}>
        <Typography level="h4">{t("item.gameServerBuild")}</Typography>
        <Card
          orientation="horizontal"
          sx={{ minHeight: 87, overflow: "hidden" }}
        >
          <CardContent
            sx={{
              flexGrow: 1,
              gap: 1,
              ...((isError || !spaceId) && { justifyContent: "center" })
            }}
          >
            {spaceId ? (
              <>
                <Fade
                  in={
                    isFetchingBuild ||
                    (isFetchingStatus && !gameServerBuildStatus)
                  }
                  timeout={200}
                  unmountOnExit
                >
                  <Stack
                    sx={{
                      position: "absolute",
                      inset: 0,
                      bgcolor: "background.surface",
                      zIndex: 9998,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <CircularProgress data-testid="loading" size="md" />
                  </Stack>
                </Fade>

                {isError && (
                  <Typography
                    color="warning"
                    level="body-sm"
                    fontStyle="italic"
                  >
                    {errCode
                      ? i18n.exists(`error.gameServerBuildStatus.${errCode}`)
                        ? t(`error.gameServerBuildStatus.${errCode}`)
                        : message
                      : t("error.gameServerBuildStatus.generic")}
                  </Typography>
                )}

                {!!gameServerBuild && !!gameServerBuildStatus && (
                  <>
                    <Typography
                      level="body-md"
                      component="h2"
                      sx={{
                        fontWeight: "normal",
                        color: "text.primary",
                        display: "inline-flex"
                      }}
                    >
                      <Link
                        color="neutral"
                        component={RouterLink}
                        to={`/${env}/game-server-builds/${gameId}?${new URLSearchParams({ search: gameServerBuild.buildId }).toString()}`}
                        sx={{ color: ({ palette }) => palette.text.primary }}
                      >
                        {gameServerBuild.buildName}
                      </Link>
                      <Chip
                        color={getBuildStatusColor(
                          gameServerBuildStatus.status
                        )}
                        data-testid="game-server-build-status"
                        sx={{ ml: 1 }}
                      >
                        {t(
                          GameServerDeployedState[gameServerBuildStatus.status]
                        )}
                      </Chip>
                    </Typography>
                    <Typography level="body-sm">
                      <CopyToClipboard>
                        {gameServerBuild.buildId}
                      </CopyToClipboard>
                    </Typography>
                  </>
                )}
              </>
            ) : (
              <Typography color="warning" level="body-sm" fontStyle="italic">
                {t("error.gameServerBuildStatus.noSpace")}
              </Typography>
            )}
          </CardContent>

          {!!spaceId && (
            <CardOverflow
              sx={{ padding: 0, flexDirection: "column", minWidth: 120 }}
            >
              <ButtonGroup
                orientation="vertical"
                variant="plain"
                sx={{
                  flexGrow: 1,
                  borderLeft: 1,
                  borderLeftColor: "divider",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
              >
                <Button
                  disabled={true}
                  startDecorator={<EditPencil />}
                  sx={{ flexGrow: 1 }}
                >
                  {t("action.update")}
                </Button>
                <Button
                  data-testid="action-button"
                  startDecorator={getGameServerActionIcon(
                    gameServerBuildStatus?.status
                  )}
                  loading={isDeploying || isStopping}
                  loadingPosition="start"
                  disabled={
                    isError || isFetching || isNotUploaded || spaceId === null
                  }
                  onClick={onActionButtonClick}
                  sx={{ flexGrow: 1 }}
                >
                  {(isDeploying && t("state.deploying")) ||
                    (isStopping && t("state.stopping")) ||
                    (isReady && !isStopped && t("action.stop")) ||
                    t("action.warm")}
                </Button>
              </ButtonGroup>
            </CardOverflow>
          )}
        </Card>
      </Stack>
    </>
  )
}
