import {
  Operation,
  PlayerSearchRequest,
  formatJsonToString
} from "@liveops-portal/lib"
import { Button, Divider, Stack, Typography } from "@mui/joy"
import { Fade } from "@mui/material"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { CustomErrorsList } from "@/components/custom-errors-list/custom-errors-list"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormSelect } from "@/components/form-select/form-select"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { Spinner } from "@/components/spinner/spinner"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useUniservicesConfigStatus } from "@/hooks/useUniservicesConfigStatus"
import { useResetThreadOfFateProgressionMutation } from "@/store/api/battlepass"
import { useLazyFindPlayerNamecardProfilesBySdkuidQuery } from "@/store/api/player"
import {
  setActiveOperation,
  resetOperation,
  selectActiveOperation
} from "@/store/slices/audit"
import { selectSpace } from "@/store/slices/context"

interface FormData extends PlayerSearchRequest {}

export const PlayerFinderPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const spaceId = useAppSelector(selectSpace)
  const activeOperation = useAppSelector(selectActiveOperation)
  const { errors, isFetching, isUninitialized } = useUniservicesConfigStatus()
  const [
    fetchBySdkuid,
    { data: playerProfile, isLoading: isLoadingPlayerProfile }
  ] = useLazyFindPlayerNamecardProfilesBySdkuidQuery()
  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: { id: "", type: "sdkuid" }
  })

  // RESET THREAD OF FATE PROGRESSION
  const [resetThreadOfFateProgression, resetThreadOfFateProgressionMeta] =
    useResetThreadOfFateProgressionMutation()
  const resetToFProgressionOperation: Operation = ["ToFProgression", "reset"]
  const isResetToFProgressionOperationActive = isOperationActive(
    activeOperation,
    resetToFProgressionOperation
  )
  const isLoadingReset =
    isResetToFProgressionOperationActive ||
    resetThreadOfFateProgressionMeta.isLoading

  const onSubmitSearchHandler = () => {
    if (methods.getValues("type") === "sdkuid") {
      fetchBySdkuid({
        sdkuid: methods.getValues("id"),
        spaceId
      })
    }
  }

  const onResetToFProgressionHandler = () => {
    if (isResetToFProgressionOperationActive && playerProfile) {
      const { PlayfabProfileId } = playerProfile
      resetThreadOfFateProgression({
        spaceId,
        accountId: PlayfabProfileId as string
      })
    }
  }

  useEffect(() => {
    if (
      resetThreadOfFateProgressionMeta.isSuccess ||
      resetThreadOfFateProgressionMeta.isError
    ) {
      dispatch(resetOperation())
    }
  }, [
    dispatch,
    resetThreadOfFateProgressionMeta.isError,
    resetThreadOfFateProgressionMeta.isSuccess
  ])

  return isUninitialized ? (
    <Typography>
      {t("message.uninitialized.root", {
        parent: "item.space"
      })}
    </Typography>
  ) : (
    <>
      <Spinner loading={isFetching} />

      {Object.keys(errors).length ? (
        <CustomErrorsList errors={errors} />
      ) : (
        <Stack sx={{ gap: 2, flexGrow: 1 }}>
          <OperationReason
            open={isResetToFProgressionOperationActive}
            onSubmit={onResetToFProgressionHandler}
            autofocus
            closeable
          />

          <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Form
              disabled={isLoadingPlayerProfile || isLoadingReset}
              sx={{ gap: 1, flexDirection: "row" }}
              methods={methods}
              onSubmit={onSubmitSearchHandler}
              submitLabel={t("action.search")}
              loading={isLoadingPlayerProfile}
            >
              <FormInput
                aria-label={t("label.playerId")}
                name="id"
                disabled={isLoadingPlayerProfile || isLoadingReset}
                rules={{
                  required: t("error.required", { label: "ID" })
                }}
                endDecorator={
                  <>
                    <Divider orientation="vertical" />
                    <FormSelect
                      slotProps={{
                        listbox: {
                          variant: "outlined"
                        }
                      }}
                      selectSx={{
                        mr: -1,
                        "&:hover": { bgcolor: "transparent" }
                      }}
                      disabled={isLoadingPlayerProfile || isLoadingReset}
                      variant="plain"
                      name="type"
                      options={[
                        { value: "sdkuid", label: "SDKUID" },
                        { value: "xbl", label: "XBL", disabled: true },
                        { value: "psn", label: "PSN", disabled: true },
                        { value: "steam", label: "STEAM", disabled: true }
                      ]}
                    />
                  </>
                }
              />
            </Form>
            <Fade in={!!playerProfile}>
              <Button
                color="danger"
                loading={isLoadingReset}
                disabled={!playerProfile || isLoadingReset}
                onClick={() => {
                  dispatch(setActiveOperation(resetToFProgressionOperation))
                }}
              >
                {t("label.resetToFProgression")}
              </Button>
            </Fade>
          </Stack>
          <Stack sx={{ flexGrow: 1, position: "relative" }}>
            <CodeEditor
              readOnly
              name="playerNamecard"
              label={t("label.response")}
              value={formatJsonToString(playerProfile)}
              sx={{ flexGrow: 1 }}
            />
          </Stack>
        </Stack>
      )}
    </>
  )
}
