export const Environments: Record<string, string> = {
  ONLINEDEV: "online-dev",
  DEV: "dev",
  STAGING: "staging",
  LIVE: "live"
} as const

export const EnvironmentsParam: Record<Environment, string> = {
  "online-dev": "ONLINEDEV",
  dev: "DEV",
  staging: "STAGING",
  live: "LIVE"
}

export type Environment = (typeof Environments)[keyof typeof Environments]

export type ByEnvQuery = {
  env: Environment | null
}
