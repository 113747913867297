import { ActionsMenu } from "@liveops-portal/lib"
import {
  Card,
  Typography,
  Divider,
  Stack,
  Chip,
  StackProps,
  CardProps,
  CardContent,
  ChipProps,
  TypographyProps,
  ColorPaletteProp
} from "@mui/joy"
import React, { HTMLProps, ReactNode } from "react"
import { CardActions } from "@/components/card-actions/card-actions"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"

export interface CardItemProps extends Omit<CardProps, "title"> {
  active?: boolean
  actions?: ActionsMenu
  title: {
    content: ReactNode
    copiable?: boolean
    props?: TypographyProps
  }
  chips?: {
    content: string
    color?: string
    props?: ChipProps & HTMLProps<HTMLSpanElement>
  }[]
  maxChips?: number
  subtitles?: {
    label?: string
    content: ReactNode
    color?: string
    copiable?: boolean
    props?: TypographyProps
  }[]
  sections?: {
    title?: string
    content: ReactNode
    props?: StackProps
  }[]
}

export const CardItem: React.FC<CardItemProps> = ({
  title,
  active,
  chips,
  maxChips = 10,
  subtitles,
  sections,
  actions,
  sx,
  ...props
}: CardItemProps) => {
  return (
    <Card
      {...props}
      sx={{
        flexGrow: 1,
        ...(active && {
          animation: "borderMove .3s infinite linear",
          backgroundImage: ({ palette }) =>
            `linear-gradient(90deg, var(--Active-borderColor, ${palette.neutral.outlinedBorder}) 50%, transparent 0),
            linear-gradient(90deg, var(--Active-borderColor, ${palette.neutral.outlinedBorder}) 50%, transparent 0),
            linear-gradient(0deg, var(--Active-borderColor, ${palette.neutral.outlinedBorder}) 50%, transparent 0),
            linear-gradient(0deg, var(--Active-borderColor, ${palette.neutral.outlinedBorder}) 50%, transparent 0)`,
          backgroundRepeat: "repeat-x, repeat-x, repeat-y, repeat-y",
          backgroundSize: " 10px 1px, 10px 1px, 1px 10px, 1px 10px",
          backgroundPosition: "0 0, 0 100%, 0 0, 100% 0",
          borderColor: "transparent"
        }),
        ...sx
      }}
    >
      <CardContent>
        {!!actions && <CardActions active={active} actions={actions} />}
        <Stack
          sx={{ flexDirection: "row", alignItems: "center", flexGrow: 1 }}
          divider={
            <Divider
              orientation="vertical"
              inset="context"
              sx={{ ml: 2, mr: 2 }}
            />
          }
        >
          <Stack sx={{ flexGrow: 1, gap: "5px" }}>
            <Stack sx={{ gap: 1, flexDirection: "row", flexWrap: "wrap" }}>
              <Typography
                level="body-md"
                component="h2"
                sx={{
                  fontWeight: "normal",
                  textColor: "text.primary",
                  display: "flex",
                  alignItems: "center",
                  gap: 1
                }}
                {...title.props}
              >
                {title.copiable ? (
                  <CopyToClipboard>{title.content}</CopyToClipboard>
                ) : (
                  title.content
                )}
              </Typography>
              {chips
                ?.slice(0, maxChips)
                .map(({ content, color, props: chipProps }, i) => (
                  <Chip
                    key={`card-chip-${i}`}
                    color={(color as ColorPaletteProp) || "neutral"}
                    {...chipProps}
                  >
                    {content}
                  </Chip>
                ))}
              {!!chips?.length && chips.length >= maxChips && (
                <Chip>+{chips.length - maxChips}</Chip>
              )}
            </Stack>
            {subtitles?.length &&
              subtitles.map(
                (
                  { label, content, color, copiable, props: subtitleProps },
                  index
                ) => (
                  <Typography
                    level="body-xs"
                    key={`card-subtitle-${index}`}
                    color={color as ColorPaletteProp}
                    {...subtitleProps}
                  >
                    {label && <Typography>{label}: </Typography>}
                    {copiable ? (
                      <CopyToClipboard>{content}</CopyToClipboard>
                    ) : (
                      content
                    )}
                  </Typography>
                )
              )}
          </Stack>

          {sections?.length &&
            sections.map(({ title, content, props: stackProps }, i) => (
              <Stack key={`card-section-${i}`} {...stackProps}>
                {title && (
                  <Typography
                    level="body-xs"
                    sx={{ fontWeight: 600, textColor: "text.secondary" }}
                  >
                    {title}
                  </Typography>
                )}
                {typeof content === "string" ? (
                  <Typography>{content}</Typography>
                ) : (
                  content
                )}
              </Stack>
            ))}
        </Stack>
      </CardContent>
    </Card>
  )
}
