import { Stack, Typography } from "@mui/joy"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { CardItem } from "@/components/card-item/card-item"
import { DeploymentSet } from "@/components/deployment-set/deployment-set"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { usePageActions } from "@/hooks/usePageActions"
import { useGetBundlesQuery } from "@/store/api/bundle"
import { useGetActiveDeploymentQuery } from "@/store/api/deployment"
import { selectGame, selectSpace } from "@/store/slices/context"

export const ActiveDeploymentPage: React.FC = () => {
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const { t } = useTranslation()

  const {
    data: deployment,
    isUninitialized,
    isFetching: isFetchingDeployment
  } = useGetActiveDeploymentQuery(
    { gameId, spaceId },
    {
      skip: !gameId || !spaceId
    }
  )

  const { bundle, isFetching: isFetchingBundle } = useGetBundlesQuery(
    { gameId },
    {
      skip: !deployment,
      selectFromResult: ({ data, isFetching }) => ({
        bundle: data?.find(
          (bundle) => bundle.bundleId === deployment?.bundleId
        ),
        isFetching
      })
    }
  )

  usePageActions([<DeploymentSet />])

  return isUninitialized ? (
    <Typography>
      {t("message.uninitialized.root", {
        parent: "item.space"
      })}
    </Typography>
  ) : (
    <>
      <Spinner loading={isFetchingDeployment || isFetchingBundle} />

      {bundle && deployment ? (
        <Stack gap={2}>
          <Typography level="h3">
            {t("label.deployment.deployedBundleInformation")}
          </Typography>
          <CardItem
            title={{
              content: `${bundle.bundleName}`
            }}
            subtitles={[
              {
                label: t("label.deployment.deploymentDate"),
                content: dayjs(deployment.deploymentDate).format(
                  "YYYY-MM-DD HH:mm:ss z"
                )
              },
              {
                label: t("label.deployment.deploymentId"),
                content: deployment.deploymentId
              },
              {
                label: t("label.bundle.bundleId"),
                content: bundle.bundleId
              },
              {
                label: t("label.bundle.bundleVersion"),
                content: bundle.bundleVersion
              }
            ]}
          />
        </Stack>
      ) : (
        <Typography>{t("error.deployment.emptyDeployment")}</Typography>
      )}
    </>
  )
}
