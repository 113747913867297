/**
 * Finds a dot notation path value in an object
 * Helpful to retrieve deeply nested values from form defaultValues or errors
 * e.g.: field.0.value
 * @param obj
 * @param path
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findNestedValue = (obj: Record<string, any>, path: string) =>
  path.split(".").reduce((acc, key) => acc?.[key], obj)
