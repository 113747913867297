export interface HttpError {
  message: string
  path: string
  statusCode: number
  timestamp: string
  errors?: Record<string, string>
}

export interface ApiError {
  message?: string
  error?: string
  errCode?: number | string
  errCategory?: string
  statusCode?: number
}

// CUSTOM ERRORS

export enum ErrorKey {
  EmptySpaceConfiguration = "EmptySpaceConfiguration",
  MissingConfigurationCategory = "MissingConfigurationCategory",
  MissingConfigurationKeys = "MissingConfigurationKeys"
}

export type CustomErrors = Record<string, Partial<Record<ErrorKey, string>>>
