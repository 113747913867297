import { Actions, Bundle } from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import { Plus } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ActiveDeploymentModal } from "@/components/active-deployment-modal/active-deployment-modal"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useCreateActiveDeploymentMutation } from "@/store/api/deployment"
import { selectGame, selectSpace } from "@/store/slices/context"

interface FormData extends Pick<Bundle, "bundleId" | "bundleVersion"> {}

export const DeploymentSet: React.FC = () => {
  const dispatch = useAppDispatch()
  const methods = useForm<FormData>({ mode: "all" })
  const { reset: resetForm } = methods
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const [createActiveDeployment, mutation] = useCreateActiveDeploymentMutation()
  const { isSuccess, isLoading: isLoadingCreate } = mutation

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm])

  const onSubmitHandler = (data: FormData) =>
    createActiveDeployment({ ...data, gameId, spaceId })

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [dispatch, isSuccess, onCloseHandler])

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        startDecorator={<Plus />}
      >
        {`${t("action.set")} ${t("item.activeDeployment")}`}
      </Button>

      <ActiveDeploymentModal
        open={open}
        loading={isLoadingCreate}
        action={Actions.set}
        methods={methods}
        onSubmit={onSubmitHandler}
        onClose={onCloseHandler}
      />
    </>
  )
}
