import { Actions, Season, SeasonRevision } from "@liveops-portal/lib"
import dayjs from "dayjs"
import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { SeasonPublishModal } from "@/components/season-modal/season-publish-modal"
import { useAppSelector } from "@/hooks/store"
import {
  useGetReferenceDateQuery,
  usePublishSeasonRevisionMutation
} from "@/store/api/season"
import { selectGame } from "@/store/slices/context"

interface Props extends Partial<Season> {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  seasonId: string
  latestDraftSeasonRevision: SeasonRevision
}

interface FormData extends Partial<Props> {
  revision: number
  referenceDate: string
}

export const SeasonPublish: React.FC<Props> = ({
  open,
  setOpen,
  seasonId,
  latestDraftSeasonRevision
}: Props) => {
  const gameId = useAppSelector(selectGame)

  const { data, isFetching: isFetchingReferenceDate } =
    useGetReferenceDateQuery(
      { gameId, seasonId },
      { skip: !open || latestDraftSeasonRevision.revision === 0 }
    )

  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      revision: -1,
      referenceDate: ""
    },
    values: {
      revision: latestDraftSeasonRevision.revision,
      referenceDate: dayjs(data?.referenceDate).format("YYYY-MM-DDTHH:mm")
    }
  })
  const { reset: resetForm } = methods

  const [publishSeasonRevision, publishSeasonRevisionMeta] =
    usePublishSeasonRevisionMutation()
  const { isSuccess, isLoading } = publishSeasonRevisionMeta

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm, setOpen])

  const onSubmitHandler = (data: FormData) =>
    publishSeasonRevision({ ...data, gameId, seasonId })

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [isSuccess, onCloseHandler])

  return (
    <SeasonPublishModal
      open={open}
      loading={isLoading || isFetchingReferenceDate}
      action={Actions.publish}
      methods={methods}
      onSubmit={onSubmitHandler}
      onClose={onCloseHandler}
    />
  )
}
